<template>
  <b-card
    title="Grafico de Atendentes Percentual"
  >
    <apexchart
      type="pie"
      height="190"
      :options="labels"
      :series="series"
    />
  </b-card>
</template>

<script>
export default {
  name: 'Atendimento',
  // eslint-disable-next-line vue/require-prop-types
  props: ['series', 'labels'],
}
</script>

<style scoped>
.label__title {
  color: #000 !important;
}
</style>
