<template>
  <section>
    <b-card>
      <loading
        :active="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
      />
      <b-row>
        <b-col md="6">
          <b-form-group>
            <h5>Selecione o Período</h5>
            <flat-pickr
              v-model="rangeDate"
              class="form-control"
              :config="{ mode: 'range', dateFormat: 'Y-m-d', locale: Portuguese, altFormat: 'd/m/Y', altInput: true }"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-button
            block
            variant="warning"
            class="botao"
            @click="buscaDados(1)"
          >
            Escolher Período
          </b-button>
        </b-col>
        <b-col
          md="3"
          class="mt-0"
        >
          <downloadexcel
            class="btn"
            name="Leads Pesa.XLS"
            :fetch="fetchData"
            :worksheet="rangeDate"
            :fields="json_fields"
            :before-generate="startDownload"
            :before-finish="finishDownload"
          >
            <b-button
              block
              class="download"
            >
              Download
            </b-button>
          </downloadexcel>
        </b-col>
      </b-row>
    </b-card>
    <!--  GRAFICO - Registros Diario  -->
    <b-row>
      <b-col md="4">
        <b-row>
          <b-col md="12">
            <b-card
              :title="'Total de '+qualificacoes+' Atendimentos'"
            >
              <apexchart
                type="pie"
                height="190"
                :options="dadosPizza"
                :series="dadosPizza.series"
              />
            </b-card>
            <!--            <b-card-->
            <!--              :title="'Total de '+totalRegistros+' Cadastros'"-->
            <!--            >-->
            <!--              <apexchart-->
            <!--                type="pie"-->
            <!--                height="190"-->
            <!--                :options="RegiAtivados"-->
            <!--                :series="RegiAtivados.series"-->
            <!--              />-->
            <!--            </b-card>-->
          </b-col>
        </b-row>
      </b-col>
      <b-col md="8">
        <b-card title="Registros Diario">
          <apexchart
            heigth="200"
            type="area"
            :options="registrosDiario"
            :series="registrosDiario.series"
          />
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="8">
        <atendimentos
          ref="foo"
          :series="atseries"
          :opcoes="atopcoes"
        />
      </b-col>
      <b-col md="4">
        <atendimentos-pessoas
          :labels="aPlabels"
          :series="aPseries"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="5">
        <!--        <atendimentos-status-->
        <!--          class="atebndimentostatus"-->
        <!--          :labels="grafAtendimentos"-->
        <!--          :sub-labels="grafsubLabels"-->
        <!--          :values="grafvalues"-->
        <!--          :colors="grafcolors"-->
        <!--        />-->
      </b-col>
    </b-row>
  </section>
</template>

<script>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import flatPickr from 'vue-flatpickr-component'
import { Portuguese } from 'flatpickr/dist/l10n/pt'
import downloadexcel from 'vue-json-excel'
import Atendimentos from './graf/Atendimentos.vue'
// import AtendimentosStatus from './graf/AtendimentoStatus.vue'
import AtendimentosPessoas from './graf/AtendimentosPessoas.vue'

export default {
  name: 'Certificados',
  components: {
    Loading,
    flatPickr,
    downloadexcel,
    Atendimentos,
    // AtendimentosStatus,
    AtendimentosPessoas,
  },
  data() {
    return {
      qualificacoes: 0,
      dadosPizza: {},
      aPlabels: {},
      aPseries: [],
      grafsubLabels: [],
      grafvalues: [],
      grafcolors: [],
      grafAtendimentos: [],
      atseries: [],
      atopcoes: {},
      isLoading: false,
      fullPage: true,
      registrosDiario: {},
      RegiAtivados: {},
      totalRegistros: 0,
      rangeDate: null,
      Portuguese,
      json_fields: {
        'Nome Empresa': 'nomeemp',
        Nome: 'nomecontato',
        Email: 'emailcontato',
        Telefone: 'telefonecontato',
        Observacao: 'observacaolead',
        'Melhor horario de Contato': 'melhorhorarioctt',
        'Produto de Interesse': 'produtosinteresse',
        'Data Criacao': 'created_at',
        Status: 'status',
        Campanha: 'campanha',
        'Campanha Nome': 'campanha_nome',
        Converteu: 'nomestatus',
      },
    }
  },
  created() {
    this.buscaDados()
    this.GraficoAtendimentos()
  },
  methods: {
    async fetchData() {
      const response = await this.$http.get(`exportarleads?data=${this.rangeDate}`)
      return response.data
    },
    startDownload() {
      this.isLoading = true
    },
    finishDownload() {
      this.isLoading = false
    },
    async buscaDados() {
      this.isLoading = true
      await this.$http.get(`graficos?data=${this.rangeDate}`).then(resp => {
        this.registrosDiario = this.GraficoDia('Leads', resp.data.diario.r_dia)
        this.RegiAtivados = this.AtivosInativos(resp.data.diario)
        this.dadosPizza = this.StatusAt(resp.data.status)
        // this.dadosPizza = resp.data.status
        // this.dadosPizzaSeries = []
        // this.dadosPizzaOpcoes = []
        // resp.data.status.forEach(dados => {
        //   this.dadosPizzaSeries.push(dados.nome)
        //   this.dadosPizzaOpcoes.push(dados.envios)
        // })
        this.graficoAtendimentos(resp.data.gfat)
        this.GraficoAtendimentos(resp.data.status)
        this.GraficoAtendentes(resp.data.gfat.t_atendimento)
      })
      this.isLoading = false
    },
    graficoAtendimentos(grafico) {
      const series = []
      const datas = []
      grafico.at_pessoa.forEach(resp => {
        series.push({ name: resp.nome, data: resp.gf })
      })
      grafico.at_diario.forEach(data => {
        datas.push(data.nome)
      })
      this.atseries = series
      this.atopcoes = {
        chart: { height: 193, type: 'area' },
        dataLabels: { enabled: false },
        stroke: { curve: 'smooth' },
        xaxis: {
          type: 'date',
          categories: datas,
        },
        tooltip: {
          x: { format: 'dd/MM/yy HH:mm' },
        },
      }
    },
    AtivosInativos(dados) {
      this.totalRegistros = dados.lidos + dados.pendentes
      return {
        series: [dados.lidos, dados.pendentes],
        labels: ['Confirmados', 'Pendentes'],
        colors: ['#fdc919', '#2d2c2c'],
        chart: {
          type: 'donut', id: 'registros-dia', height: 370, width: 370,
        },
        responsive: [{
          options: {
            legend: { position: 'bottom' },
          },
        }],
      }
    },
    StatusAt(dados) {
      let qualific = 0
      const option = []
      const label = []
      dados.forEach(dad => {
        if (dad.nome !== 'Sem Status') {
          qualific += dad.envios
        }
        option.push(dad.envios)
        label.push(dad.nome)
      })
      this.qualificacoes = qualific
      return {
        series: option,
        labels: label,
        // colors: ['#fdc919', '#2d2c2c'],
        chart: {
          type: 'pie', id: 'registros-dia', height: 370, width: 370,
        },
        responsive: [{
          options: {
            legend: { position: 'bottom' },
          },
        }],
      }
    },
    GraficoDia(nome, dados) {
      const Categories = []
      const Series = []
      dados.forEach(registroDia => {
        Categories.push(registroDia.dt)
        Series.push(registroDia.reg)
      })
      return {
        colors: ['#2d2c2c', '#FEC91A'],
        chart: { id: 'registros-dia', height: '193' },
        xaxis: { categories: Categories },
        series: [{ name: nome, data: Series }],
        stroke: { curve: 'smooth' },
      }
    },
    GraficoAtendimentos(status) {
      if (status === undefined) {
        return false
      }
      this.grafAtendimentos = []
      this.grafsubLabels = ['Atendimentos']
      this.grafvalues = []
      status.forEach(statu => {
        this.grafAtendimentos.push(statu.nome)
        this.grafvalues.push([statu.envios])
      })
      this.grafcolors = ['#fdc919', '#000']
      return true
    },
    // eslint-disable-next-line no-unused-vars
    GraficoAtendentes(valor) {
      const nomes = []
      const atend = []
      valor.forEach(v => {
        nomes.push(v.nome)
        atend.push(v.atendimentos)
      })
      this.aPlabels = { labels: nomes }
      this.aPseries = atend
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style scoped>
.botao{
  border-color: #fbd351 !important;
  background-color: #fdc919 !important;
  margin-top: 23px;
}
.download{
  background-color: #2d2c2c !important;
  border-color: #fdc333 !important;
  margin-top: 10px;
}
.btn-warning {
  color: #1e191a !important;
}
.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__title {
  font-size: 12px;
  font-weight: bold;
  color: #2d2d2d !important;
}
</style>
