<template>
  <b-card
    :title="'Grafico de atendimentos'"
  >
    <apexchart
      type="area"
      height="170"
      :options="opcoes"
      :series="series"
    />
  </b-card>
</template>

<script>
export default {
  name: 'Atendimento',
  // eslint-disable-next-line vue/require-prop-types
  props: ['series', 'opcoes'],
}
</script>

<style scoped>
.label__title {
  color: #000 !important;
}
</style>
